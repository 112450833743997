import React, { useState, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";
import _ from "lodash";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// COMPONENTS
import PollDataComponent from "../components/PollDataComponent";
import ScrollingBoxWrapper from "../components/ScrollingBoxWrapper";

import List from "../polls/List";
import TierList from "../polls/TierList";
import TugofWar from "../polls/TugofWar";
import TugofWarEmote from "../polls/TugofWarEmote";

import Countdown, { zeroPad } from "react-countdown";

// ACCORDION
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Chip from "@mui/material/Chip";

// ICONS
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/DeleteForever";
import Edit from "@mui/icons-material/Edit";
import Publish from "@mui/icons-material/Publish";
import Save from "@mui/icons-material/LibraryAdd";
import Clear from "@mui/icons-material/Clear";

// MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#333",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
};

const modalStyleEdit = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   height: "80vh",
   width: "80vw",
   bgcolor: "#ffffff",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
   overflow: "auto",
};

// COUNTDOWN
const renderer = ({ minutes, seconds, completed }) => {
   if (completed) {
      return "Completed";
   } else {
      return (
         <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
         </span>
      );
   }
};

const getTitle = (type) => {
   switch (type) {
      case "list":
         return "List";
         break;
      case "tugofwar":
         return "Tug of War";
         break;
      case "tugofwaremote":
         return "Tug of War Emote";
         break;
   }
};

export default function Polls(props) {
   const socket = useContext(SocketContext);

   const headerHeight = useRef();
   const gridHeight = headerHeight.current
      ? props.height -
        parseInt(window.getComputedStyle(headerHeight.current).height)
      : 0;
   const gridRow = gridHeight / 2;

   const [channelId, setChannelId] = useState();
   const [showConnect, setShowConnect] = useState(false);
   const [debug, setDebug] = useState();
   const [debugVisible, setDebugVisible] = useState(false);
   const [connectionState, setConnectionState] = useState();
   const [stream, setStream] = useState();

   const [lastUpdated, setLastUpdated] = useState(Date.now());

   const [emotes, setEmotes] = useState([]);
   const [channelEmotes, setChannelEmotes] = useState([]);

   const [polls, setPolls] = useState([]);
   const [savedPolls, setSavedPolls] = useState([]);
   const [activePoll, setActivePoll] = useState();

   const [styles, setStyles] = useState({});
   const [tugofWarStyles, setTugofWarStyles] = useState({});

   const [activeResultTotal, setActiveResultTotal] = useState(0);
   const [activeResults, setActiveResults] = useState();
   const [activeTimer, setActiveTimer] = useState(false);

   const [activeTugofWar, setActiveTugofWar] = useState();
   const [activeTugofWarResultTotal, setActiveTugofWarResultTotal] =
      useState(0);
   const [activeTugofWarResults, setActiveTugofWarResults] = useState();

   const [reduction, setReduction] = useState(-0.8);

   const handleReductionChange = (event) => {
      setReduction(parseFloat(event.target.value));
      socket.emit("updateTugofWarReduction", parseFloat(event.target.value));
   };

   // EMOTE FUNCTIONS
   const getEmoteData = (id) => {
      let allEmotes = emotes.concat(channelEmotes);

      let index = _.findIndex(
         allEmotes,
         (row) => {
            return row.id === id;
         },
         0
      );

      if (index === -1) {
         return [{ id: "", name: "Emote Not Found" }];
      } else {
         return [allEmotes[index]];
      }
   };

   // ACCORDION
   const [expanded, setExpanded] = useState(false);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   const [expandedCompleted, setExpandedCompleted] = useState(false);

   const handleChangeCompleted = (panel) => (event, isExpanded) => {
      setExpandedCompleted(isExpanded ? panel : false);
   };

   // MODAL
   const [editPoll, setEditPoll] = useState({});
   const [modalOpen, setModalOpen] = useState(false);
   const handleOpen = (poll) => {
      setEditPoll(poll);
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   // ADD POLL OPEN
   const [addPoll, setAddPoll] = useState("");
   const [modalAddOpen, setModalAddOpen] = useState(false);

   const handlePollAdd = (type) => {
      setAddPoll(type);
      setModalAddOpen(true);
   };
   const handlePollAddClose = () => {
      setModalAddOpen(false);
   };

   const pollTypes = {
      list: List,
      tierlist: TierList,
      tugofwar: TugofWar,
      tugofwaremote: TugofWarEmote,
   };

   // SET ADD POLL COMPONENTS MODAL
   const PollComponentAdd = pollTypes[addPoll];

   // SET POLL COMPONENTS SIDEBAR
   const PollComponent = pollTypes[editPoll.type];

   // USE EFFECTS
   useEffect(() => {
      socket.emit("getData", (response) => {
         setConnectionState(response.globals.connectionState);
         setPolls(response.polls);
         setSavedPolls(response.savedPolls);
         setActivePoll(response.activePoll);

         setActiveTugofWar(response.activeTugofWar);

         if (response.activePoll.endTime > Date.now()) {
            setActiveTimer(true);
         } else {
            setActiveTimer(false);
         }
         setChannelId(response.channelId);
      });

      socket.emit("getStyles", (response) => {
         setStyles(response.styles);
         setTugofWarStyles(response.stylesTugofWar);
      });

      socket.emit("getEmotes", (response) => {
         setEmotes(response.global);
         setChannelEmotes(response.channel);
      });

      socket.on("activeResults", (total, results) => {
         setActiveResultTotal(total);
         setActiveResults(results);
      });

      socket.on("activeTugofWarResults", (total, results) => {
         setActiveTugofWarResultTotal(total);
         setActiveTugofWarResults(results);
      });
   }, []);

   useEffect(() => {
      socket.on("activeResults", (total, results) => {
         setActiveResultTotal(total);
         setActiveResults(results);
      });

      socket.on("activeTugofWarResults", (total, results) => {
         setActiveTugofWarResultTotal(total);
         setActiveTugofWarResults(results);
      });

      // REFRESH CALLS
      socket.on("refreshPolls", () => {
         socket.emit("getData", (response) => {
            setPolls(response.polls);
            setSavedPolls(response.savedPolls);
            setActivePoll(response.activePoll);
            setActiveTugofWar(response.activeTugofWar);
         });
      });

      socket.on("refreshStyles", () => {
         socket.emit("getStyles", (response) => {
            setStyles(response.styles);
            setTugofWarStyles(response.stylesTugofWar);
         });
      });

      socket.on("refreshEmotes", () => {
         socket.emit("getEmotes", (response) => {
            setEmotes(response.global);
            setChannelEmotes(response.channel);
         });
      });

      // LOGGING
      socket.on("error", (data) => {
         setConnectionState(data);
      });

      socket.on("debug", (data) => {
         setDebug(data);
      });

      socket.on("stream", (data) => {
         setStream(data);
      });
   }, [socket]);

   // CHANNELID UPDATE EFFECT
   useEffect(() => {
      setTimeout(function () {
         socket.emit("getEmotes", (response) => {
            setEmotes(response.global);
            setChannelEmotes(response.channel);
         });
      }, 2000);
   }, [channelId]);

   // HANDLERS
   const sendChannelId = () => {
      setShowConnect(false);
      socket.emit("updateChannelId", channelId);
   };

   const getStyles = (stylesObj) => {
      let styleObj = Object.entries(stylesObj).map((layout, i) => {
         return {
            id: layout[0],
            title: layout[1].global
               ? layout[1].global.parameters.layoutTitle
               : "Layout " + i,
         };
      });

      return styleObj;
   };

   const refreshDBStyles = () => {
      socket.emit("refreshDBStyles");
   };

   const handleStagePoll = (poll) => {
      socket.emit("stagePoll", poll);
      handlePollAddClose();
      setLastUpdated(Date.now());
   };

   const handleUpdatePoll = (poll) => {
      socket.emit("updatePoll", poll);
      setLastUpdated(Date.now());
   };

   const handleLoadPoll = (poll) => {
      if (poll.type != "tugofwar") {
         setActiveTimer(false);
         {
            /* setActiveResults(); */
         }
         socket.emit("loadPoll", poll);
      }

      if (poll.type === "tugofwar") {
         {
            /* setActiveTugofWarResults(); */
         }
         setReduction(-0.8);
         socket.emit("loadPoll", poll);
      }
   };

   const handleDeletePoll = (id) => {
      socket.emit("deletePoll", id);
   };

   const handleLoadSavedPoll = (poll) => {
      setActiveTimer(false);
      {
         /* setActiveResults(); */
      }
      socket.emit("loadSavedPoll", poll);
   };

   const handleDeleteSavedPoll = (id) => {
      socket.emit("deleteSavedPoll", id);
   };

   const handleUpdateSavedPoll = (poll) => {
      socket.emit("updateSavedPoll", poll);
   };

   const handleStartPoll = () => {
      setActiveTimer(true);
      socket.emit("startPoll");
   };

   const handleStartTugofWar = () => {
      socket.emit("startTugofWar");
   };

   const handleStopTugofWar = () => {
      socket.emit("stopTugofWar");
   };

   const handleZeroPoll = () => {
      socket.emit("zeroPoll");
   };

   const handleZeroTugofWar = () => {
      socket.emit("zeroTugofWar");
   };

   const handleSavePoll = (poll, activeResults, totalResults) => {
      poll.results = activeResults;
      socket.emit("saveActivePoll", poll, totalResults);
   };

   const handleResetPoll = () => {
      setActiveTimer(false);
      socket.emit("resetPoll");
   };

   return (
      <>
         <Box
            sx={{
               boxSizing: "border-box",
               height: "100%",
               width: "100%",
               position: "relative",
            }}
         >
            {/* PARENT GRID */}
            <Grid
               container
               sx={{ height: "100%" }}
               direction='row'
               justifyContent='center'
               alignItems='stretch'
               columnSpacing={2}
               rowSpacing={2}
            >
               <Grid item xs={12} ref={headerHeight}>
                  <Grid
                     container
                     direction='row'
                     justifyContent='center'
                     alignItems='center'
                  >
                     {/* HEADER */}
                     <Grid item xs={7}>
                        <Grid
                           container
                           justifyContent='center'
                           alignItems='center'
                           columnSpacing={2}
                        >
                           <Grid item xs={4}>
                              <TextField
                                 key='channelId'
                                 label='Twitch Channel'
                                 placeholder='eg. crown'
                                 name='channelId'
                                 id='filled-size-small'
                                 value={channelId ? channelId : ""}
                                 variant='outlined'
                                 size='small'
                                 onChange={(e) => {
                                    setChannelId(e.target.value);
                                    setShowConnect(true);
                                 }}
                                 fullWidth
                              />
                           </Grid>
                           {connectionState === "Disconnected" && (
                              <Grid item xs={3}>
                                 <Button
                                    size='small'
                                    color='success'
                                    variant='outlined'
                                    onClick={sendChannelId}
                                    fullWidth
                                 >
                                    CONNECT
                                 </Button>
                              </Grid>
                           )}
                           {connectionState !== "Disconnected" &&
                              showConnect && (
                                 <Grid item xs={3}>
                                    <Button
                                       size='small'
                                       color='warning'
                                       variant='outlined'
                                       onClick={sendChannelId}
                                       fullWidth
                                    >
                                       CHANGE CHANNEL
                                    </Button>
                                 </Grid>
                              )}
                           <Grid
                              item
                              xs={
                                 connectionState !== "Disconnected" &&
                                 !showConnect
                                    ? 8
                                    : 5
                              }
                              sx={{
                                 color:
                                    connectionState &&
                                    connectionState.includes("Connected")
                                       ? "green"
                                       : "red",
                              }}
                           >
                              {connectionState}
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item xs={5}>
                        <Grid
                           container
                           columnSpacing={2}
                           rowSpacing={1}
                           justifyContent='right'
                           alignItems='flex-start'
                        >
                           <Grid item>
                              <Button
                                 variant='text'
                                 color='secondary'
                                 size='small'
                                 onClick={() => setDebugVisible(!debugVisible)}
                              >
                                 Toggle Debug
                              </Button>
                           </Grid>
                           <Grid item>
                              <Button
                                 variant='outlined'
                                 href='/editor'
                                 size='small'
                              >
                                 EDIT POLL LAYOUTS
                              </Button>
                           </Grid>
                           <Grid item>
                              <Button
                                 variant='outlined'
                                 href='/editor/tugofwar'
                                 size='small'
                              >
                                 EDIT TUG OF WAR LAYOUTS
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               {/* ACTIVE POLLS */}

               {/* STAGED POLLS */}
               <Grid item xs={6} sx={{ height: gridRow }}>
                  <ScrollingBoxWrapper
                     title='Staged Polls'
                     parentHeight={gridRow}
                     border='2px solid #333'
                     buttons={
                        <>
                           <Button
                              color='info'
                              onClick={() => handlePollAdd("list")}
                              size='small'
                              sx={{ ml: 1 }}
                           >
                              + List Poll
                           </Button>
                           <Button
                              color='info'
                              onClick={() => handlePollAdd("tugofwar")}
                              size='small'
                              sx={{ ml: 1 }}
                           >
                              + Tug of War Poll
                           </Button>
                           <Button
                              color='info'
                              onClick={() => handlePollAdd("tugofwaremote")}
                              size='small'
                              sx={{ ml: 1 }}
                           >
                              + Emote Poll
                           </Button>
                        </>
                     }
                  >
                     {polls.length > 0 &&
                        polls.map((poll, p) => (
                           <Accordion
                              expanded={expanded === "accordion" + p}
                              onChange={handleChange("accordion" + p)}
                           >
                              <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                 aria-controls='panel1bh-content'
                                 id='panel1bh-header'
                              >
                                 <Grid
                                    container
                                    direction='row'
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={2}
                                 >
                                    <Grid item xs={2}>
                                       <Chip
                                          label={poll.type.toUpperCase()}
                                          size='small'
                                          sx={{
                                             width: "100%",
                                             backgroundColor:
                                                poll.type === "list"
                                                   ? "rgba(50,80,200,1)"
                                                   : "rgba(100,0,100,1)",
                                          }}
                                       />
                                    </Grid>
                                    <Grid item xs={6}>
                                       {poll.title}
                                    </Grid>
                                    <Grid
                                       item
                                       xs={4}
                                       sx={{
                                          textAlign: "right",
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       <Button
                                          variant='outlined'
                                          size='small'
                                          sx={{ mr: 2 }}
                                          color='info'
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             handleLoadPoll(poll);
                                          }}
                                          startIcon={<Publish />}
                                       >
                                          Load
                                       </Button>
                                    </Grid>
                                 </Grid>
                              </AccordionSummary>
                              <AccordionDetails sx={{ position: "relative" }}>
                                 <Button
                                    color='warning'
                                    onClick={(e) => {
                                       e.stopPropagation();
                                       handleOpen(poll);
                                    }}
                                    startIcon={<Edit />}
                                    sx={{
                                       position: "absolute",
                                       zIndex: 10,
                                       top: 15,
                                       right: 120,
                                    }}
                                 >
                                    Edit
                                 </Button>

                                 <Button
                                    color='error'
                                    onClick={(e) => {
                                       e.stopPropagation();
                                       handleDeletePoll(poll.id);
                                    }}
                                    startIcon={<Delete />}
                                    sx={{
                                       position: "absolute",
                                       zIndex: 10,
                                       top: 15,
                                       right: 23,
                                    }}
                                 >
                                    Delete
                                 </Button>

                                 <PollDataComponent
                                    key={"poll" + poll.id}
                                    poll={poll}
                                    handleOpen={handleOpen}
                                    updatePoll={handleUpdatePoll}
                                    loadPoll={handleLoadPoll}
                                    layouts={
                                       poll.type === "tugofwar"
                                          ? getStyles(tugofWarStyles)
                                          : getStyles(styles)
                                    }
                                    edit={true}
                                    emotes={emotes.concat(channelEmotes)}
                                 />
                              </AccordionDetails>
                           </Accordion>
                        ))}
                  </ScrollingBoxWrapper>
               </Grid>
               <Grid item xs={6} sx={{ height: gridRow }}>
                  <ScrollingBoxWrapper
                     title='Active List Poll'
                     parentHeight={gridRow}
                     border='2px solid #333'
                  >
                     {/* ACTIVE LIST POLL */}
                     {activePoll && Object.entries(activePoll).length > 0 && (
                        <Box
                           sx={{
                              minHeight: "100%",
                              p: 2,
                              position: "relative",
                           }}
                           className={activeTimer ? "activeTimer" : ""}
                        >
                           {!activeTimer && (
                              <Box sx={{ textAlign: "right" }}>
                                 <Button
                                    size='small'
                                    color='warning'
                                    onClick={() => {
                                       handleSavePoll(
                                          activePoll,
                                          activeResults,
                                          activeResultTotal
                                       );
                                    }}
                                    sx={{ ml: 1 }}
                                    startIcon={<Save />}
                                 >
                                    Save Completed Poll
                                 </Button>

                                 <Button
                                    size='small'
                                    color='error'
                                    onClick={handleZeroPoll}
                                    sx={{ ml: 1 }}
                                    startIcon={<Clear />}
                                 >
                                    Zero Results
                                 </Button>

                                 <Button
                                    variant='outlined'
                                    size='small'
                                    color='success'
                                    onClick={handleStartPoll}
                                    sx={{ ml: 1 }}
                                 >
                                    Start Timer
                                 </Button>
                              </Box>
                           )}
                           {activeTimer && (
                              <Box sx={{ textAlign: "right" }}>
                                 <Button
                                    variant='outlined'
                                    size='small'
                                    color='error'
                                    onClick={handleResetPoll}
                                 >
                                    Stop Poll
                                 </Button>
                              </Box>
                           )}
                           <br />
                           <b>Total Answers:</b> {activeResultTotal}
                           <br />
                           <br />
                           {activePoll.title && (
                              <>
                                 <b>Title: {activePoll.title}</b>
                                 <br />
                              </>
                           )}
                           {!activeResults && (
                              <>
                                 <b>Answers</b>
                                 <ul>
                                    {Object.entries(activePoll.answers)
                                       .sort()
                                       .map((answer) => (
                                          <li
                                             key={
                                                "active-" +
                                                activePoll.id +
                                                answer[0]
                                             }
                                          >
                                             {answer[0]}
                                             {activePoll.type === "list" && (
                                                <>: {answer[1]}</>
                                             )}
                                          </li>
                                       ))}
                                 </ul>
                              </>
                           )}
                           {activeResults && (
                              <>
                                 <b>Live Results</b>
                                 {Object.entries(activeResults)
                                    .sort()
                                    .map((result) => (
                                       <Grid
                                          container
                                          key={
                                             "activeLiveResult-" +
                                             activePoll.id +
                                             result[0]
                                          }
                                       >
                                          <Grid item xs={2}>
                                             {result[0]}
                                          </Grid>
                                          <Grid item xs={4}>
                                             {result[1].answer}
                                          </Grid>
                                          <Grid item xs={3}>
                                             {result[1].qty}
                                          </Grid>
                                          <Grid item xs={3}>
                                             {result[1].qty === 0
                                                ? "0"
                                                : Math.floor(
                                                     (result[1].qty /
                                                        activeResultTotal) *
                                                        100
                                                  )}
                                             %
                                          </Grid>
                                       </Grid>
                                    ))}
                              </>
                           )}
                           <br />
                           <b>Duration:</b> {activePoll.duration} seconds
                           <br />
                           <b>Last Run:</b>
                           {new Date(activePoll.lastRun).toLocaleString()}
                           <br />
                           {activePoll.endTime > Date.now() && (
                              <>
                                 <b>Time Remaining: </b>{" "}
                                 <Countdown
                                    date={new Date(activePoll.endTime)}
                                    renderer={renderer}
                                    onComplete={() => setActiveTimer(false)}
                                 />
                              </>
                           )}
                        </Box>
                     )}
                  </ScrollingBoxWrapper>
               </Grid>

               {/* COMPLETED POLLS */}
               <Grid item xs={6} sx={{ height: gridRow }}>
                  <ScrollingBoxWrapper
                     title='Completed Polls'
                     parentHeight={gridRow}
                     border='2px solid #333'
                  >
                     {savedPolls.length > 0 &&
                        savedPolls.map((poll, p) => (
                           <Accordion
                              expanded={
                                 expandedCompleted === "savedPollAccordion" + p
                              }
                              onChange={handleChangeCompleted(
                                 "savedPollAccordion" + p
                              )}
                           >
                              <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                 aria-controls='panel1bh-content'
                                 id='panel1bh-header'
                              >
                                 <Grid
                                    container
                                    direction='row'
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={2}
                                 >
                                    <Grid item xs={2}>
                                       <Chip
                                          label={poll.type.toUpperCase()}
                                          size='small'
                                          sx={{
                                             width: "100%",
                                             backgroundColor:
                                                poll.type === "list"
                                                   ? "rgba(50,80,200,1)"
                                                   : "rgba(100,0,100,1)",
                                             mr: 1,
                                          }}
                                       />
                                    </Grid>
                                    <Grid item xs={6}>
                                       {poll.title}
                                       {Object.entries(poll.results)
                                          .sort()
                                          .map((result) => (
                                             <Grid
                                                container
                                                key={
                                                   "saved-" +
                                                   poll.id +
                                                   result[0]
                                                }
                                             >
                                                <Grid item xs={2}>
                                                   {result[0]}
                                                </Grid>
                                                <Grid item xs={4}>
                                                   {result[1].answer}
                                                </Grid>
                                                <Grid item xs={3}>
                                                   {result[1].qty}
                                                </Grid>
                                                <Grid item xs={3}>
                                                   {result[1].qty === 0
                                                      ? "0"
                                                      : Math.floor(
                                                           (result[1].qty /
                                                              poll.totalResults) *
                                                              100
                                                        )}
                                                   %
                                                </Grid>
                                             </Grid>
                                          ))}
                                    </Grid>
                                    <Grid
                                       item
                                       xs={4}
                                       sx={{ textAlign: "right" }}
                                    >
                                       <Button
                                          variant='outlined'
                                          size='small'
                                          sx={{ mr: 2 }}
                                          color='info'
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             handleLoadSavedPoll(poll);
                                          }}
                                          startIcon={<Publish />}
                                       >
                                          Load
                                       </Button>

                                       <IconButton
                                          color='error'
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             handleDeleteSavedPoll(poll.id);
                                          }}
                                       >
                                          <Delete />
                                       </IconButton>
                                    </Grid>
                                 </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                 <PollDataComponent
                                    key={"poll" + poll.id}
                                    poll={poll}
                                    handleOpen={handleOpen}
                                    updatePoll={handleUpdatePoll}
                                    loadPoll={handleLoadPoll}
                                    layouts={
                                       poll.type === "tugofwar"
                                          ? getStyles(tugofWarStyles)
                                          : getStyles(styles)
                                    }
                                    edit={true}
                                    emotes={emotes.concat(channelEmotes)}
                                 />
                              </AccordionDetails>
                           </Accordion>
                        ))}
                  </ScrollingBoxWrapper>
               </Grid>

               {/* ACTIVE TUG OF WAR POLLS */}
               <Grid item xs={6} sx={{ height: gridRow }}>
                  <ScrollingBoxWrapper
                     title='Active Tug of War Poll'
                     parentHeight={gridRow}
                     border='2px solid #333'
                  >
                     {activeTugofWar &&
                        Object.entries(activeTugofWar).length > 0 && (
                           <Box
                              sx={{
                                 minHeight: "100%",
                                 p: 2,
                                 position: "relative",
                              }}
                              className={
                                 activeTugofWar.lastRun ? "activeTugofWar" : ""
                              }
                           >
                              {activeTugofWar.lastRun && (
                                 <Box sx={{ textAlign: "right" }}>
                                    <Button
                                       variant='outlined'
                                       size='small'
                                       color='error'
                                       sx={{ ml: 1 }}
                                       onClick={handleStopTugofWar}
                                    >
                                       Stop
                                    </Button>
                                 </Box>
                              )}
                              {!activeTugofWar.lastRun && (
                                 <Box sx={{ textAlign: "right" }}>
                                    <Button
                                       size='small'
                                       color='error'
                                       onClick={handleZeroTugofWar}
                                       sx={{ ml: 1 }}
                                       startIcon={<Clear />}
                                    >
                                       Zero Results
                                    </Button>

                                    <Button
                                       variant='outlined'
                                       size='small'
                                       color='success'
                                       sx={{ ml: 1 }}
                                       onClick={handleStartTugofWar}
                                    >
                                       Start Tug of War
                                    </Button>
                                 </Box>
                              )}
                              <br />
                              {activeTugofWar.title && (
                                 <>
                                    <b>Title: {activeTugofWar.title}</b>
                                 </>
                              )}
                              <br />
                              {!activeResults && (
                                 <>
                                    <b>Answers</b>
                                    <ul>
                                       {Object.entries(activeTugofWar.answers)
                                          .sort()
                                          .map((answer) => (
                                             <li
                                                key={
                                                   "active-" +
                                                   activeTugofWar.id +
                                                   answer[0]
                                                }
                                             >
                                                {activeTugofWar.type ===
                                                   "tugofwar" &&
                                                   activeTugofWar.emote && (
                                                      <>
                                                         {answer[0]}:{" "}
                                                         {answer[1] &&
                                                            getEmoteData(
                                                               answer[1]
                                                            ).map((data) => (
                                                               <React.Fragment
                                                                  key={
                                                                     "emoteId" +
                                                                     data.id
                                                                  }
                                                               >
                                                                  {data &&
                                                                     data.images && (
                                                                        <img
                                                                           src={
                                                                              data
                                                                                 .images
                                                                                 .url_2x
                                                                           }
                                                                           style={{
                                                                              marginLeft:
                                                                                 "1em",
                                                                              marginRight:
                                                                                 "1em",
                                                                           }}
                                                                           alt='Emote'
                                                                        />
                                                                     )}
                                                                  {data && (
                                                                     <b
                                                                        style={{
                                                                           color:
                                                                              data.id ===
                                                                              ""
                                                                                 ? "red"
                                                                                 : "",
                                                                        }}
                                                                     >
                                                                        {
                                                                           data.name
                                                                        }
                                                                     </b>
                                                                  )}
                                                               </React.Fragment>
                                                            ))}
                                                      </>
                                                   )}
                                             </li>
                                          ))}
                                    </ul>
                                 </>
                              )}
                              {activeTugofWarResults && (
                                 <>
                                    <b>Live Results</b>
                                    {Object.entries(activeTugofWarResults)
                                       .sort()
                                       .map((result) => (
                                          <Grid
                                             container
                                             key={
                                                "activeTugofWarLiveResults-" +
                                                activePoll.id +
                                                result[0]
                                             }
                                             justifyContent='center'
                                             alignItems='center'
                                             sx={{ mt: 2 }}
                                          >
                                             <Grid item xs={1}>
                                                {result[0]}
                                             </Grid>

                                             {!activeTugofWar.emote && (
                                                <Grid item xs={5}>
                                                   {result[1].answer}
                                                </Grid>
                                             )}

                                             {activeTugofWar.emote &&
                                                result[1].answer &&
                                                getEmoteData(
                                                   result[1].answer
                                                ).map((data) => (
                                                   <React.Fragment
                                                      key={
                                                         "activeTugofWarEmote-" +
                                                         activePoll.id +
                                                         result[1]
                                                      }
                                                   >
                                                      <Grid
                                                         item
                                                         xs={2}
                                                         sx={{
                                                            textAlign: "right",
                                                         }}
                                                      >
                                                         {data &&
                                                            data.images && (
                                                               <img
                                                                  src={
                                                                     data.images
                                                                        .url_2x
                                                                  }
                                                                  style={{
                                                                     marginLeft:
                                                                        "1em",
                                                                     marginRight:
                                                                        "1em",
                                                                     width: 30,
                                                                  }}
                                                                  alt='Emote'
                                                               />
                                                            )}
                                                      </Grid>
                                                      <Grid
                                                         item
                                                         xs={3}
                                                         sx={{
                                                            textAlign: "left",
                                                         }}
                                                      >
                                                         {data && (
                                                            <b
                                                               style={{
                                                                  color:
                                                                     data.id ===
                                                                     ""
                                                                        ? "red"
                                                                        : "",
                                                               }}
                                                            >
                                                               {data.name}
                                                            </b>
                                                         )}
                                                      </Grid>
                                                   </React.Fragment>
                                                ))}
                                             <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                   textAlign: "center",
                                                }}
                                             >
                                                <b>QTY</b>
                                                <br />
                                                {result[1].qty}
                                             </Grid>
                                             <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                   textAlign: "center",
                                                }}
                                             >
                                                <b>REAL</b>
                                                <br />
                                                {result[1].qty === 0
                                                   ? "0"
                                                   : Math.floor(
                                                        (result[1].qty /
                                                           activeTugofWarResultTotal) *
                                                           100
                                                     )}
                                                %
                                             </Grid>
                                             <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                   textAlign: "center",
                                                }}
                                             >
                                                <b>ADJ</b>
                                                <br />
                                                {result[1].qty === 0
                                                   ? "0"
                                                   : Math.min(
                                                        100,
                                                        Math.max(
                                                           0,
                                                           Math.floor(
                                                              (result[1].qty /
                                                                 activeTugofWarResultTotal) *
                                                                 (1 +
                                                                    parseFloat(
                                                                       reduction
                                                                    )) *
                                                                 100
                                                           )
                                                        )
                                                     )}
                                                %
                                             </Grid>
                                          </Grid>
                                       ))}
                                 </>
                              )}
                              <br />
                              <br />

                              <FormControl sx={{ textAlign: "center" }}>
                                 <FormLabel id='reduction-radio-buttons-group'>
                                    <b>Leader Adjustment:</b>
                                 </FormLabel>
                                 <RadioGroup
                                    aria-labelledby='reduction-radio-buttons-group'
                                    name='reduction-radio-buttons-group'
                                    value={reduction}
                                    onChange={handleReductionChange}
                                 >
                                    <Grid container>
                                       <Grid item xs={4}>
                                          <FormControlLabel
                                             size='small'
                                             value={-0.2}
                                             control={<Radio />}
                                             label='-20%'
                                          />
                                          <FormControlLabel
                                             size='small'
                                             value={-0.4}
                                             control={<Radio />}
                                             label='-40%'
                                          />
                                          <FormControlLabel
                                             size='small'
                                             value={-0.6}
                                             control={<Radio />}
                                             label='-60%'
                                          />
                                          <FormControlLabel
                                             size='small'
                                             value={-0.8}
                                             control={<Radio />}
                                             label='-80%'
                                          />
                                       </Grid>
                                       <Grid item xs={4}>
                                          <FormControlLabel
                                             size='small'
                                             value={0}
                                             control={<Radio />}
                                             label='0%'
                                          />
                                       </Grid>
                                       <Grid item xs={4}>
                                          <FormControlLabel
                                             size='small'
                                             value={0.2}
                                             control={<Radio />}
                                             label='+20%'
                                          />
                                          <FormControlLabel
                                             size='small'
                                             value={0.4}
                                             control={<Radio />}
                                             label='+40%'
                                          />
                                          <FormControlLabel
                                             size='small'
                                             value={0.6}
                                             control={<Radio />}
                                             label='+60%'
                                          />
                                          <FormControlLabel
                                             size='small'
                                             value={0.8}
                                             control={<Radio />}
                                             label='+80%'
                                          />
                                       </Grid>
                                    </Grid>
                                 </RadioGroup>
                              </FormControl>
                              <br />
                              <br />
                              {activeTugofWar.lastRun && (
                                 <>
                                    <b>Started at:</b>
                                    {new Date(
                                       activeTugofWar.lastRun
                                    ).toLocaleString()}
                                    <br />
                                    <br />
                                 </>
                              )}
                           </Box>
                        )}
                  </ScrollingBoxWrapper>
               </Grid>
            </Grid>
         </Box>

         {debugVisible && (
            <>
               {/* DEBUG AND STREAM LOGGING */}
               <Box
                  sx={{
                     position: "fixed",
                     bottom: 0,
                     right: 0,
                     pointerEvents: "none",
                     color: "#fff",
                     backgroundColor: "rgba(0,0,0,.5)",
                     p: 4,
                     transformOrigin: "bottom right",
                     transform: "scale(0.5)",
                     boxSizing: "border-box",
                  }}
               >
                  <pre>{JSON.stringify(debug, null, 4)}</pre>
               </Box>
               <Box
                  sx={{
                     position: "fixed",
                     bottom: 0,
                     left: 0,
                     pointerEvents: "none",
                     color: "#fff",
                     backgroundColor: "rgba(0,0,0,.7)",
                     p: 4,
                     transformOrigin: "bottom left",
                     transform: "scale(0.5)",
                     width: "100%",
                     boxSizing: "border-box",
                  }}
               >
                  <pre>{JSON.stringify(stream, null, 4)}</pre>
               </Box>
            </>
         )}

         {/* MODAL - EDIT POLL */}
         <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Edit Poll
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  {PollComponent && (
                     <>
                        {editPoll.emote && (
                           <TugofWarEmote
                              poll={editPoll}
                              updatePoll={handleUpdatePoll}
                              layouts={
                                 editPoll.type === "tugofwar"
                                    ? getStyles(tugofWarStyles)
                                    : getStyles(styles)
                              }
                              edit={true}
                              closeModal={handleClose}
                           />
                        )}
                        {!editPoll.emote && (
                           <PollComponent
                              poll={editPoll}
                              updatePoll={handleUpdatePoll}
                              layouts={
                                 editPoll.type === "tugofwar"
                                    ? getStyles(tugofWarStyles)
                                    : getStyles(styles)
                              }
                              edit={true}
                              closeModal={handleClose}
                           />
                        )}
                     </>
                  )}
               </Typography>
            </Box>
         </Modal>

         {/* MODAL - ADD NEW POLL */}
         <Modal
            open={modalAddOpen}
            onClose={handlePollAddClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography
                  id='modal-title'
                  variant='h6'
                  component='h2'
                  sx={{ textTransform: "uppercase" }}
               >
                  Add New {getTitle(addPoll)} Poll
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  {PollComponentAdd && (
                     <PollComponentAdd
                        stagePoll={handleStagePoll}
                        lastUpdated={lastUpdated}
                        layouts={
                           addPoll === "tugofwar" || addPoll === "tugofwaremote"
                              ? getStyles(tugofWarStyles)
                              : getStyles(styles)
                        }
                        closeModal={handlePollAddClose}
                     />
                  )}
               </Typography>
            </Box>
         </Modal>

         <Button
            variant='outlined'
            color='error'
            onClick={refreshDBStyles}
            size='small'
         >
            Reload DB
         </Button>
      </>
   );
}
