import { useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function ScrollingBoxWrapper({
   children,
   title,
   parentHeight,
   buttons,
   border,
}) {
   const headerHeight = useRef();
   const scrollerHeight = headerHeight.current
      ? parentHeight -
        35 -
        parseInt(window.getComputedStyle(headerHeight.current).height)
      : 0;

   return (
      <>
         <Typography
            variant='h5'
            gutterBottom
            component='div'
            ref={headerHeight}
         >
            <Grid
               container
               direction='row'
               justifyContent='left'
               alignItems='center'
            >
               <Grid item>{title}</Grid>
               {buttons && (
                  <Grid item xs sx={{ textAlign: "right" }}>
                     {buttons}
                  </Grid>
               )}
            </Grid>
         </Typography>
         <Box sx={{ height: scrollerHeight, overflow: "auto", border: border }}>
            {children}
         </Box>
      </>
   );
}
